.orderViewForm-items {
  > .AddItemPanel {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    width: 100%;
    border: 1px solid #5b5b5b;

    > .SelectedItem {
      display: flex;
      justify-content: center;
      align-items: baseline;
      color: wheat;
      padding: 0 3px 0 3px;
      margin: 15px;
      border: 1px solid #5b5b5b;

      .title {
        padding-top: 4px;
      }


      .quantity {
        padding-top: 4px;
        margin-left: 3px;
        margin-right: 6px;
      }

      .quantity-buttons {
        position: relative;
        left: 0;
        top: -5px;
        height: 22px;
        display: flex;
        flex-direction: column;
      }

      > .quantity-buttons > span {
        color: #861010;
        cursor: pointer;
      }

      > .quantity-buttons > span:hover {
        color: red;
        cursor: pointer;
      }

      > .closing {
        position: relative;
        top: -10px;
        width: 10px;
        left: -3px;
        padding-left: 3px;
        font-size: 12px;
      }

      > .closing > span {
        color: #861010;
      }

      > .closing > span:hover {
        cursor: pointer;
        color: red;
      }
    }
  }
}


.addItem-modal {
  text-align: center;

  > div {
    margin-bottom: 15px;
  }

  > .AddItemPanel {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    width: 100%;
    border: 1px solid #5b5b5b;

    > .SelectedItem {
      display: flex;
      justify-content: center;
      align-items: baseline;
      color: wheat;
      padding: 0 3px 0 3px;
      margin: 15px;
      border: 1px solid #5b5b5b;

      .title {
        padding-top: 4px;
      }


      .quantity {
        padding-top: 4px;
        margin-left: 3px;
        margin-right: 6px;
      }

      .quantity-buttons {
        position: relative;
        left: 0;
        top: -5px;
        height: 22px;
        display: flex;
        flex-direction: column;
      }

      > .quantity-buttons > span {
        color: #861010;
        cursor: pointer;
      }

      > .quantity-buttons > span:hover {
        color: red;
        cursor: pointer;
      }

      > .closing {
        position: relative;
        top: -10px;
        width: 10px;
        left: -3px;
        padding-left: 3px;
        font-size: 12px;
      }

      > .closing > span {
        color: #861010;
      }

      > .closing > span:hover {
        cursor: pointer;
        color: red;
      }
    }
  }

  > div > div > button {
    width: 80%;
  }

  > div > div > .ant-input-group-wrapper {
    width: 80%;
  }

  > div > div > .ant-input-group-wrapper .ant-input-group-addon {
    padding: 0;
  }
}
.assign_row {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.assign_radio {
  margin: unset !important;
  color: wheat;

  > div > div > div > div > label {
    color: wheat;
    margin: unset;
  }
}
.ant-input-affix-wrapper > input {
  background: inherit !important;
}

.ant-select-selector {
  background: inherit !important;
}

.ant-input-group-wrapper > span > input {
  background: inherit !important;
}
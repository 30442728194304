.fc .fc-daygrid-day-frame {
  min-height: 150px !important;
}

.full-calendar_wrapper {
  margin: 15px;
}

.calendarPopUp-priority {
  width: 20px;
  text-align: right;
  display: block;
  font-weight: bold;
}

.calendarPopUp-Collection {
  color: #007bff;
}

.calendarPopUp-Delivery {
  color: #dc3545;
}

.calendarPopUp-row {
  flex-wrap: nowrap;
  border-bottom: 1px solid #4b4b4b;
}

.calendarPopUp-title {
  font-size: 22px;
  padding-left: 7px;
  border-bottom: 1px solid #4b4b4b;
  margin: 7px 0px;
}

.calendarPopUp {
  border: 1px solid #0d423f;
  padding: 5px;
  position: absolute;
  min-width: 500px;
  z-index: 2;
}

//@import '../../node_modules/antd/lib/style/themes/default.less';
@import "../../node_modules/react-multi-date-picker/styles/backgrounds/bg-dark.css";
@import "../../node_modules/react-multi-date-picker/styles/colors/yellow.css";

@import "./antd";
@import "./logist";
@import "./assignModal";
@import "./addItemModal";

.text-lg {
  font-size: 300px;
}

.antd_menu {
  position: relative;
  top: 13.3%;
}

.theme-switcher-form {
  display: inline-block;
}

.theme-switcher-form-item {
  display: block;
  margin-bottom: 10px;
}

.site-layout {
  min-height: 100vh !important;
}

.header-search {
  flex-grow: 1 !important;
}

.header-search.ant-menu-item-selected::after {
  border: unset !important;
}

.header-search.ant-menu-item:hover {
  border: unset !important;
}

// Import Ant Design styles by less entry
//@import 'custom.dark.less';

//@import 'olddriver/index.less';
//@import 'office/index.less';
//
//@import 'theme/seagoat.less';

//.container-box {
//  margin: 0 15px;
//  display: flex;
//  justify-content: center;
//}
//
//.color-block {
//  height: 22px;
//  width: 80%
//}
//
//* {
//  box-sizing: border-box;
//}
//
//tbody {
//  tr:nth-child(odd) {
//    background: darken(@table-bg, 4%) !important;
//  }
//}
//
//.background-Delivery {
//  background: @delivery-color;
//}
//
//.background-Collection {
//  background: @collection-color;
//}
//
//.Delivery {
//  color: @text-delivery-color;
//}
//
//.Collection {
//  color: @text-collection-color;
//}
//
//.rmdp-container {
//  display: unset;
//}
//
//.rmdp-input {
//  box-sizing: border-box;
//  margin: 0 !important;
//  padding: 0;
//  font-variant: tabular-nums;
//  list-style: none;
//  font-feature-settings: 'tnum', "tnum";
//  position: relative;
//  display: inline-block;
//  width: 100%;
//  min-width: 0;
//  padding: 4px 11px !important;
//  color: #f3dcb1 !important;
//  font-size: 14px !important;
//  height: 32px !important;
//  line-height: 1.5715 !important;
//  background-color: #35333d !important;
//  background-image: none !important;
//  border: 1px solid #5b5b5b !important;
//  border-radius: 2px !important;
//  transition: all 0.3s !important;
//}
//
//.ant-menu-item-selected {
//  color: whitesmoke;
//}
//
//.ant-menu-item-active {
//  color: lightgrey !important;
//}
//
//.ant-form-item-control-input-content {
//  .rmdp-container {
//    width: 100%
//  }
//}
//

.logist-margin {
  margin-bottom: 400px;
}

.Completed-Area {
  background-color: green;
  color: whitesmoke;
}

.Completed-Button {
  background-color: green;
  color: whitesmoke;
}

.Completed-Button:hover {
  background-color: lightgreen;
  color: whitesmoke !important;
  border: unset !important;
}

.order-view-submit-button {
  background-color: green;
  color: whitesmoke;
}

.order-view-submit-button:hover {
  background-color: lightgreen;
  color: whitesmoke;
}

.order-view-input-center {
  text-align: center;

  div > div > div > span > .ant-input {
    text-align: center;
    font-size: 16px;
  }

  div > div > div > .ant-input {
    text-align: center;
    font-size: 16px;
  }
}

//
//.margin-0 {
//  margin: 0 !important;
//}
//
//.route-view-operation-actions {
//  padding-left: 5px;
//  background-color: #35333d;
//  background-image: none;
//  border: 1px solid #5b5b5b;
//}
//
//.draggable-operation-grid {
//  background-color: #333333;
//}
//
//.AppHeader_search_select {
//  //div >div>
//}
